<template>
    <div>AccountReviewHistoryIndex</div>
</template>

<script>
    export default {
        name: 'AccountReviewHistoryIndex',

        created() {
            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Account', routeName: 'AccountProfile' },
                { text: 'Reviews', routeName: 'AccountReviewIndex' },
                { text: 'My Review', routeName: 'AccountReviewHistoryIndex' }
            ]);
        }
    };
</script>
